
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import ImageField from '@/components/common/form/ImageField.vue';
import {ListItem} from '@/components/common/form/PillInput.vue';
import PillInputEmail from '@/components/common/form/PillInputEmail.vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';
import TeamType from '@/library/enumerations/TeamType';
import userTeams from '@/store/userTeams';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
        ImageField,
        PillInputEmail,
    },
    props: {
        addLeaders: {
            type: Boolean,
            default: false,
        },
        addMembers: {
            type: Boolean,
            default: false,
        },
        editTeam: {
            type: Object as PropType<Team>,
            default: () => new Team(),
        },
    },
    emits: [
        'team-saved',
    ],
    setup(props) {
        const teamAttributes = props.editTeam.id
            ? props.editTeam.attributes
            : {
                parentId: userTeams.teamProgram.value.id,
                type: TeamType.TEAM,
            };

        const {teamProgram} = userTeams;

        return {
            team: ref<Team>(new Team(teamAttributes, props.editTeam!.collection)),
            teamLeaders: ref<ListItem[]>([]),
            teamMembers: ref<ListItem[]>([]),
            teamProgram,
        };
    },
    watch: {
        addMembers(value: boolean) {
            if (!value) {
                this.teamMembers = [];
            }
        },
    },
    methods: {
        setTeamLogo(logo: string) {
            if (!this.team.logo) {
                this.team.logo = {
                    original: logo,
                };

                return;
            }

            this.team.logo.original = logo;
        },
        assignMembers() {
            this.team.sync = [];

            this.teamLeaders.forEach((listItem: ListItem) => {
                this.team.sync!.push({
                    email: listItem.item,
                    roles: [
                        Role.ADMIN,
                    ],
                });
            });

            this.teamMembers.forEach((listItem: ListItem) => {
                this.team.sync!.push({
                    email: listItem.item,
                });
            });
        },
        async saveTeam() {
            this.assignMembers();

            try {
                await this.team.save();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                return;
            }

            this.$emit('team-saved', this.team);
        },
    },
});
