import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch" }
const _hoisted_2 = {
  key: 0,
  class: "border-top pt-6 mb-12 mt-6"
}
const _hoisted_3 = { class: "d-inline-block" }
const _hoisted_4 = { class: "text-muted ms-12" }
const _hoisted_5 = { class: "mt-1 text-muted small" }
const _hoisted_6 = {
  key: 1,
  class: "border-top pt-6 mb-12"
}
const _hoisted_7 = { class: "mt-1 text-muted small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageField = _resolveComponent("ImageField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PillInputEmail = _resolveComponent("PillInputEmail")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveTeam && _ctx.saveTeam(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ImageField, {
        "error-key": "logo",
        errors: _ctx.team.errors,
        label: _ctx.$t('teams.form.logo'),
        "model-value": _ctx.team.logo,
        size: "lg",
        "onUpdate:modelValue": _ctx.setTeamLogo
      }, null, 8, ["errors", "label", "model-value", "onUpdate:modelValue"]),
      _createVNode(_component_FormGroup, {
        modelValue: _ctx.team.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.team.name) = $event)),
        class: "min-w-400px ms-16",
        "error-key": "name",
        errors: _ctx.team.errors,
        label: _ctx.$t('teams.form.name'),
        name: "name"
      }, null, 8, ["modelValue", "errors", "label"])
    ]),
    (_ctx.addLeaders)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t('teams.form.teamLeaders')), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('teams.form.maxLeaders')), 1)
          ]),
          _createVNode(_component_PillInputEmail, {
            modelValue: _ctx.teamLeaders,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.teamLeaders) = $event)),
            class: "mt-6",
            "data-test-id": "input-teams-form-add-leaders",
            "max-items": 2
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('sentences.inputEmailSeparatedBy')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.addMembers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('teams.form.teamMembers')), 1),
          _createVNode(_component_PillInputEmail, {
            modelValue: _ctx.teamMembers,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.teamMembers) = $event)),
            class: "mt-6",
            "data-test-id": "input-teams-form-add-members"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('sentences.inputEmailSeparatedBy')), 1)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "form-submit", { team: _ctx.team }, () => [
      _createVNode(_component_BtnResource, {
        class: "mt-24",
        disabled: _ctx.team.isOriginal,
        resource: _ctx.team,
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('teams.actions.updateDetails')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "resource"])
    ])
  ], 32))
}